<template>
    <div>
        <card-header title="Customer" icon="fa-user"/>
        <card-toolbar>
            <button @click="$openCard('edit-customer', {userId: user.id}, card)"><i class="fas fa-pencil mr-2"/>Edit Customer</button>
            <button @click="$openCard('customer-share', {userId: user.id}, card)"><i class="fas fa-share-square mr-2"/>Share</button>
        </card-toolbar>

        <card-body>
            <card-list>
                <container-list-item padded class=" pb-1 mb-0 content" v-if="user.deleted">
                    <h5 class="has-text-danger"><i class="fas fa-exclamation-triangle mr-2"></i>This customer has been deleted.</h5>
                </container-list-item>

                <navigation-list-item
                    icon="fa-suitcase"
                    title="Registrations"
                    :active="child && child.definition.component === 'customer-registrations'"
                    @click="$openCard('customer-registrations', {userId: user.id}, card)"
                    :badge="user.registrations.length"
                />

                <navigation-list-item
                    title="Notes"
                    icon="fa-sticky-note"
                    :active="child && child.definition.component === 'customer-notes'"
                    @click="$openCard('customer-notes', {userId: user.id}, card)"
                    :badge="user.notes.length"
                />

                <navigation-list-item
                    title="Documents/IDs/Loyalty"
                    icon="fa-id-card"
                    :active="child && child.definition.component === 'customer-documents'"
                    @click="$openCard('customer-documents', {userId: user.id}, card)"
                    :badge="user.documents.length"
                />

                <subheader-list-item title="Communications" icon="fa-comments"/>

                <navigation-list-item
                    title="Text"
                    icon="fa-sms"
                />

                <navigation-list-item
                    title="E-Mail"
                    icon="fa-envelope-open"
                />

                <navigation-list-item
                    title="App"
                    icon="fa-mobile"
                />

                <subheader-list-item title="Customer Details" icon="fa-list"/>
                <data-row title="Photo">
                    <div class="photo-frame" v-if="!user.photo_file">
                        <i class="fat fa-user"></i>
                    </div>
                    <div class="photo-frame" v-else>
                        <img :src="apiUrl + 'app/user/photo/' + user.photo_file"/>
                    </div>
                </data-row>
                <data-row title="First">{{ user.first_name }}</data-row>
                <data-row title="Middle">{{ user.middle_name }}</data-row>
                <data-row title="Last">{{ user.last_name }}</data-row>
                <data-row title="Preferred">{{ user.preferred_name }}</data-row>
                <data-row title="Title">{{ user.title }}</data-row>
                <data-row title="E-Mail"><a :href="'mailto:' + user.email">{{ user.email }}</a></data-row>
                <data-row title="Password">{{ user.password }}</data-row>
                <data-row title="Phone 1">{{ user.phone }}</data-row>
                <data-row title="Phone 2">{{ user.phone_2 }}</data-row>
                <data-row title="Address">
                    <div>{{ user.address }}</div>
                    <div v-if="user.address_2">{{ user.address_2 }}</div>
                    <div>{{ user.city }}<span v-if="user.city">, </span>{{ user.state }} &nbsp;{{ user.zip }}</div>
                    <div>{{ user.country }}</div>
                </data-row>
                <data-row title="DOB">{{ user.dob | moment('MMM Do, YYYY') }}</data-row>
                <data-row title="Level">{{ user.tennis_level }}</data-row>
                <data-row title="Rating">{{ user.tennis_rating }}</data-row>
                <data-row title="Club">{{ user.tennis_club }}</data-row>
                <data-row title="Pro">{{ user.tennis_pro }}</data-row>
                <data-row title="Shirt Size">{{ user.shirt_size }}</data-row>
                <data-row title="Dietary">{{ user.dietary_requirements }}</data-row>
                <data-row title="Created">{{ user.created_at | moment('MMM Do, YYYY') }}</data-row>
                <separator-list-item/>
            </card-list>
        </card-body>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardList from "../../TIER/components/CardList";
    import CardToolbar from "../../TIER/components/CardToolbar";
    import SearchListItem from "../../TIER/components/SearchListItem";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import SeparatorListItem from "../../TIER/components/SeparatorListItem";
    import NavigationListItem from "../../TIER/components/NavigationListItem";
    import TripListItem from "../../components/TripListItem";

    import {client as http, apiUrl} from '../../http_client';
    import CardBody from "../../TIER/components/CardBody";
    import DataRow from "../../TIER/components/DataRow";
    import ContainerListItem from "../../TIER/components/ContainerListItem";

    export default {
        name: "Users",
        props: ['card', 'child', 'props'],
        components: {ContainerListItem, DataRow, CardBody, TripListItem, NavigationListItem, SeparatorListItem, SubheaderListItem, SearchListItem, CardToolbar, CardList, CardHeader},
        data: function() {
            return {
                user: {
                    notes: [],
                    registrations: [],
                    documents: [],
                },
                apiUrl: apiUrl

            };
        },
        methods: {
            loadUser: function(force) {
                this.$emit('loading', true);
                http.get('/api/users/' + this.props.userId, {force}).then(response => {
                    this.user = response.data;
                    this.$emit('title', this.user.first_name + ' ' + this.user.last_name);
                    this.$emit('loading', false);
                });
            },
            reload: function() {
                this.loadUser(true);
            }
        },
        watch: {
            'props.userId': function() {
                this.loadUser();
            }
        },
        mounted() {
            this.loadUser();
        }
    };
</script>

<style lang="scss" scoped>
    .profile-photo {
        margin: 0.05px 0.05px 1rem;
        background-color: white;
        border-radius: 0.3rem;
        padding: 1rem;
        cursor: pointer;
        text-align: center;
        font-size: 0.9rem;
        line-height: 1rem;

        i {
            font-size: 75px;
            color: #bebebe;
            display: block;
            margin: 0.8rem;
        }

        .photo-frame {
            border: 1px solid #e7e7e7;
            display: inline-block;
            border-radius: 0.4rem;
            width: 150px;
            height: 150px;

            i {
                padding: 1.8rem 0;
            }
        }
    }
</style>
